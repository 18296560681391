import {
	ISagaAction,
	IUserBackdoorLoginPayload,
	IUserFriendPayload,
	IUserLoginPayload,
	IUserReturnPayload,
	IUserUpdate,
} from "modules/types";
import {call, delay, put} from "typed-redux-saga";
import {Api, ApiError, extractErrorMessage, SSO_URL, User} from "modules/utils";
import * as actions from "modules/actions";
import {get} from "lodash";
import {AxiosError} from "axios";
import {IApiResponse} from "modules/utils/Api/HTTPClient";

export const userBackdoorLoginSaga = function* ({payload}: ISagaAction<IUserBackdoorLoginPayload>) {
	try {
		const {data: response} = yield* call(Api.Auth.backdoor, payload);
		ApiError.CHECK(response);

		if (response.result) {
			const {user, session_id} = response.result;
			User.SAVE(user, session_id);

			yield* put(actions.backdoorLoginSuccess(user));
		} else {
			yield* put(actions.backdoorLoginFailed("Login Failed"));
		}
	} catch (e) {
		const err = extractErrorMessage(e as AxiosError<IApiResponse>);
		yield* put(actions.globalError(err));
		yield* put(actions.backdoorLoginFailed(err));
	}
};

export const userLogoutSaga = function* () {
	try {
		User.CLEAR();

		yield* put(actions.userLogoutSuccess());
	} catch (e) {
		const err = extractErrorMessage(e as AxiosError<IApiResponse>);
		yield* put(actions.globalError(err));
	}
};

export const userLoginSaga = function* ({payload}: ISagaAction<IUserLoginPayload>) {
	try {
		const {data: response} = yield* call(Api.Auth.login, payload);
		ApiError.CHECK(response);
		if (response.result) {
			const {user, session_id, is_registered} = response.result;

			if (is_registered) {
				User.SAVE(user, session_id);

				yield* put(actions.userLoginSuccess(user));
			} else {
				yield* put(actions.userAskForRegistration(user));
			}
		} else {
			yield* put(actions.userLoginFailed());
		}
	} catch (e) {
		const code = get(e, "response.data.errors[0].code");

		if (code === 515) {
			yield* put(actions.userAskForReturn());
		} else {
			const error = extractErrorMessage(e as AxiosError<IApiResponse>);
			yield* put(actions.userLoginFailed());
			yield* put(actions.globalError(error));
		}
	}
};

export const userCreateSaga = function* ({payload}: ISagaAction<FormData>) {
	try {
		const {data: response} = yield* call(Api.User.create, payload);
		ApiError.CHECK(response);

		if (response.result) {
			const {user, session_id} = response.result;
			User.SAVE(user, session_id);

			yield* put(actions.userCreateSuccess(user));
		}
	} catch (e) {
		const err = extractErrorMessage(e as AxiosError<IApiResponse>);
		yield* put(actions.userCreateFailed());
		yield* put(actions.globalError(err));
	}
};

export const userUpdateSaga = function* (action: ISagaAction<IUserUpdate>) {
	try {
		const {data: response} = yield* call(Api.User.update, action.payload);
		ApiError.CHECK(response);

		if (response.result) {
			const {user, session_id} = response.result;
			User.SAVE(user, session_id);

			yield* put(actions.userUpdateSuccess(response.result.user));

			yield* delay(2000);

			yield* put(actions.resetUserUpdateSuccess());
		}
	} catch (e) {
		const err = extractErrorMessage(e as AxiosError<IApiResponse>);

		yield* put(actions.globalError(err));
		yield* put(actions.userUpdateFailed());
	}
};

export const userReturnSaga = function* ({payload}: ISagaAction<IUserReturnPayload>) {
	try {
		const {data: response} = yield* call(Api.User.recover, payload);

		ApiError.CHECK(response);
		if (response.result) {
			const {user, session_id} = response.result;
			User.SAVE(user, session_id);

			yield* put(actions.userReturnSuccess(user));
		}
	} catch (e) {
		const err = extractErrorMessage(e as AxiosError<IApiResponse>);
		yield* put(actions.userCreateFailed());
		yield* put(actions.globalError(err));
	}
};

export const userRegisterForGameSaga = function* ({payload}: ISagaAction<IUserReturnPayload>) {
	try {
		const {data: response} = yield* call(Api.User.register_for_game, payload);

		ApiError.CHECK(response);
		if (response.result) {
			const {user, session_id} = response.result;
			User.SAVE(user, session_id);

			yield* put(actions.userRegisterForGameSuccess(user));
		}
	} catch (e) {
		const err = extractErrorMessage(e as AxiosError<IApiResponse>);

		yield* put(actions.userCreateFailed());
		yield* put(actions.globalError(err));
	}
};

export const userFetchSaga = function* () {
	try {
		const {data: response} = yield* call(Api.User.show_my);
		ApiError.CHECK(response);

		if (response.result && response.result.user !== null) {
			const {user, session_id} = response.result;
			User.SAVE(user, session_id);

			yield* put(actions.userFetchSuccess(response.result.user));
		} else {
			yield* put(actions.userFetchFailed());
		}
	} catch (e) {
		const code = get(e, "response.data.errors[0].code");

		if (code === 515) {
			yield* put(actions.userAskForReturn());
		} else {
			yield* put(actions.userFetchFailed());
		}
	}
};

export const postUserLogoutSaga = function* () {
	try {
		const {data: response} = yield* call(Api.Auth.logout);
		ApiError.CHECK(response);
		// const script = document.createElement("script");
		// const callbackName = `jQuery${Date.now()}`;

		// set(window, callbackName, () => {
		// 	User.CLEAR();
		// 	window.location.href = `${SSO_URL}en/logout?redirect_uri=${window.location.origin}`;
		// });
		// script.src = `${SSO_URL_SCRIPT}en/user/logout/?callback=${callbackName}`;
		// document.body.appendChild(script);

		yield* put(actions.postUserLogoutSuccess());
		User.CLEAR();
		window.location.href = `${SSO_URL}en/logout?redirect_uri=${window.location.origin}`;
	} catch (e) {
		const err = extractErrorMessage(e as AxiosError<IApiResponse>);
		yield* put(actions.globalError(err));
	}
};

export const postHandleUserFriendSaga = function* ({payload}: ISagaAction<IUserFriendPayload>) {
	try {
		const apiName = payload.is_friend ? "delete_friend" : "add_friend";

		const {data: response} = yield* call(Api.User[apiName], {
			friend_id: payload.friend_id,
		});

		ApiError.CHECK(response);
	} catch (e) {
		const err = extractErrorMessage(e as AxiosError<IApiResponse>);
		yield* put(actions.userCreateFailed());
		yield* put(actions.globalError(err));
	}
};
